.related-products-title {
  text-transform: uppercase;
  text-align: center;
  h2 {
    font-size: 26px;
    font-weight: 400;
    color: #de8f75;
    line-height: 38px;
  }
}
.related-products-img {
  figure {
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    width: fit-content;
  }
}

.related-product-informations {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  li {
    font-size: 16px;
  }
}
.related-product-colors {
  display: flex;
  flex-direction: row;
  li + li {
    margin-left: 5px;
  }
}
.related-products {
  margin-top: 100px;
  margin-bottom: 200px;
}
@media (max-width: 1030px) {
  .related-product-informations {
    padding: 0px 5px;
  }
  .related-products {
    text-align: -webkit-center;
    margin: 0 auto;
  }
}
