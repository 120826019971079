

.main-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  h3 {
    text-transform: uppercase;
  }
  h3:not(.title-news) {
    font-size: 12px;
    font-weight: 700;
    color: #aeb6c1;
  }
  ul {
    li {
      margin-top: 10px;
      font-size: 14px;
    }
  }
  margin-bottom: 20px;
  margin-top: 40px;
}
.images {
  display: grid;
  ul {
    display: flex;
    flex-wrap: wrap;
    li + li {
      margin-left: 2rem;
    }
  }
}
.newsletter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #aeb6c1;
  box-sizing: border-box;
  background: #fff;
  width: 293px;
  height: 293px;
  border-radius: 100%;
  margin-top: -10rem;
  form {
    display: flex;
    flex-direction: column;
    button {
      align-self: center;
    }
    label {
      font-size: 12px;
      color: #20202050;
    }
    input + input {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  h3 {
    font-size: 16px;
    font-weight: 700;
  }
  input {
    font-size: 14px;
    display: flex;
  }
}
@media (max-width: 1030px) {
  .images {
    place-items: center;
  }
}

.newsletter form label,
.newsletter input {
  font-family: 'Montserrat';
}

.newsletter {
  form {
    div {
      align-self: center;
      text-align: center;
    }
  }
}
