html {
  height: 100vh;
  transition: all 0.2s ease-in-out;
}
body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #202020;
}
input,
button,
p,
h1,
h2,
h3 {
  font-family: 'Montserrat';
  color: #202020;
  transition: all 0.2s ease-in-out;
}
button:hover {
  filter: brightness(1.1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
ul {
  padding-inline-start: 0px;
  list-style: none;
}
input {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-color: #68686840;
}

figure {
  margin-block-start: 0em;
}
