.minicart-container {
  padding: 20px 30px 0 30px;
  p {
    margin: 0;
  }
  a {
    &:hover {
      cursor: pointer;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e2e2e2;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #de8f75;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(153, 83, 59);
  }
}
.minicart-header {
  margin-bottom: 10px;
  p {
    color: #aeb6c1;
    font-size: 18px;
    font-weight: 400;
  }
  h1 {
    color: #de8f75;
    font-size: 26px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
  }
  a {
    position: absolute;
    top: 10px;
    right: 15px;
  }
}
.product-list-minicart {
  display: flex;
  flex-direction: column;
  max-height: 650px;
  overflow-y: auto;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  li + li {
    margin-top: 50px;
  }
  div {
    justify-content: center;
    display: inline-flex;
  }
  img {
    max-width: 75px;
  }
}
.price-in-list {
  flex-direction: column;
  p {
    font-size: 12px;
  }
  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  }
}
.select-qty-in-list {
  input {
    width: 30px;
    border: none;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #5f75aa;
  }
  a {
    margin-left: 20px;
    display: flex;
    margin-right: 20px;
  }
}
.remove-prod-from-list {
  margin-right: 20px;
}
.minicart-footer {
  display: flex;
  margin-top: 2.5rem;
  flex-direction: column;
  place-content: flex-end;
}
.minicart-freight {
  text-align: center;
  padding: 10px;
  background: #aeb6c1;
  p {
    color: #fff;
    font-size: 14px;
    span {
      font-weight: 700;
    }
  }
}
.minicart-card-total {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: #e3e3e3;
  padding: 15px;
}
.minicart-total-price {
  p {
    margin: 0;
  }
  p:first-child {
    font-weight: bold;
    font-size: 16px;
  }
  p:last-child {
    font-weight: 500;
    font-size: 12px;
  }
  margin-right: 20px;
}
.minicart-button-checkout {
  button {
    width: 15rem;
    height: 50px;
  }
}
@media (max-width: 1030px) {
  .minicart-footer {
    width: inherit;
    bottom: 0;
    position: absolute;
  }
  .product-list-minicart {
    max-height: 450px;
  }
  .makeStyles-list {
    width: unset;
  }
}
