.product-section-mobile {
  text-align: center;
  figure {
    margin: 0;
  }
}
.product-informations-mobile {
  text-align: center;
  margin: 10px;
}
.product-title-mobile {
  margin: 0;
  font-weight: normal;
  font-size: 18px;
  text-transform: uppercase;
  color: #de8f75;
  margin-bottom: 5px;
}
.product-code-mobile {
  text-transform: uppercase;
  color: #7f7f7f;
  font-weight: normal;
  font-size: 12px;
}
