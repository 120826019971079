.btn-large {
  border-radius: 3px;
  height: 60px;
  border: none;
  text-transform: uppercase;
  width: -webkit-fill-available;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  font-family: 'Montserrat';
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}
