.btn-medium {
  width: 110px;
  font-family: 'Open Sans';
  border: none;
  height: 50px;
  margin: 10px;
  justify-self: center;
  border-radius: 3px;
  background-color: #de8f75;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}
