.header-logo {
  display: flex;
  justify-content: center;
  margin: 1rem 0px 2rem;
}
.breadcrumbs {
  font-family: 'Montserrat' !important;
  margin-top: 10px !important;
  font-size: 12px !important;
  margin-left: 28px !important;
  color: #aeb6c1 !important;
  line-height: 18px;
  width: 154px;
  height: 38px;
  p {
    font-weight: 700;
    font-size: 12px;
    color: #aeb6c1 !important;
    font-family: 'Montserrat';
  }
}
