.product-section {
  margin: 0px 30px 0px 30px;
}
.grid-list {
  width: 500px;
  height: 450px;
}
.grid-root {
  display: flex;
  flex-wrap: wrap;
  ul {
    max-width: 150px;

    width: max-content;
    flex-wrap: wrap;
    overflow: hidden !important;
  }
  li {
    margin-bottom: 10px;
    width: 100% !important;
  }
  figure {
    display: flex;
    object-fit: cover;
    justify-content: center;
    height: 1rem !important;
    width: 3rem;
  }
}
.carousel {
  width: fit-content !important;
}
.carousel > div {
  flex-wrap: wrap;
  justify-content: center;
  min-width: 7rem;
}

.rec.rec-arrow {
  box-shadow: none;
  background-color: #fff;
  border-radius: 0px;
  width: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 0px;
  line-height: 0px;
}
.cukhLH {
  height: 55vh;
  margin: 10px 10px;
}

.rec-carousel-item {
  margin-bottom: 1rem;
  &:hover {
    cursor: pointer;
  }
}

.product-details {
  border: 2px solid #d2e1df;
  box-sizing: border-box;
  border-radius: 3px;
  width: 444px;
  height: 533px;
  margin-left: 50px;
  padding: 15px;
}

.product-title {
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 700;
  margin: 0;
}
.product-code {
  color: #202020;
  font-size: 14px;
  font-weight: 400;
}
.product-prices {
  margin-top: 15px;
  span + span:not(.discount) {
    color: #e35442;
    font-size: 14px;
  }
}
.price {
  color: #e35442;
  font-size: 14px;
  text-decoration: line-through;
}
.discount {
  margin-left: 5px;
  color: #686868;
  font-size: 20px;
  font-weight: 700;
}
.parcels {
  margin: 0;
  font-size: 12px;
  color: #7f7f7f;
}

.colors-selected {
  margin-bottom: 10px;
}
.rec-slider-container {
  height: inherit;
}
