.links-header-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  li:last-child {
    display: flex;
    p {
      margin: 0;
      margin-top: 10px;
      margin-left: -15px;
    }
  }
}
.links-search-cart {
  display: flex;
  align-items: center;
}
.logo-mobile {
  width: 100px;
}
@media (max-width: 600px) {
  .links-header-mobile {
    display: grid;
    grid-template-columns: 20% 60% 20%;
    align-items: center;
  }
  .logo-mobile {
    justify-self: center;
    width: unset;
  }
}
