.form-div-mobile {
  form {
    text-align: center;
    display: grid;
    div {
      margin-top: 10px;
      display: flex;
      div {
        width: 100%;
      }
    }
  }
}
.form-title-mobile {
  text-align: center;
  text-transform: uppercase;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin: 0;
  color: #aeb6c1;
}

.form-div-mobile form label,
.form-div-mobile input {
  font-family: 'Montserrat';
}

.accordions {
  p {
    font-size: 14px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    color: #aeb6c1;
    font-weight: 400;
  }
}

.MuiIconButton-root {
  color: #aeb6c1 !important;
}
.MuiAccordionDetails-root {
  list-style: none;
  p {
    color: #202020;
    text-transform: capitalize;
  }
}
.accord-inst {
  border-top: 2px solid #aeb6c1;
}
.footer-mobile-content {
  margin-top: 30px;
}
.images {
  margin-top: 20px;
  margin-bottom: 20px;
}
