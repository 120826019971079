.product-addded-to-cart {
  font-size: 22px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  color: #de8f75;
  text-transform: uppercase;
  text-align: center;
}

#transition-modal-description {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  color: #aeb6c1;
}
.button-modal-close {
  display: flex !important;
  flex-direction: row-reverse !important;
  margin-bottom: 1rem !important;
  margin-right: -2rem !important;
  &:hover {
    cursor: pointer;
  }
}
.image-product-modal {
  max-height: 400px;
}
@media (max-width: 1030px) {
  .image-product-modal {
    width: 50vw;
  }
}
