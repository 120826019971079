.product-colors {
  margin-top: 35px;
  span {
    font-size: 14px;
    margin-right: 5px;
  }
  span + span {
    color: #5f75aa;
    font-size: 14px;
  }
}

.product-sizes {
  margin-right: 30px;
  ul:first-child {
    display: flex;
    justify-content: space-between;
    li {
      font-size: 14px;
    }
    li > span {
      color: #5f75aa;
    }
    li:last-child {
      color: #de8f75;
      text-decoration: underline;
    }
  }
}

.sizes {
  display: flex;
  justify-content: space-between;
  li {
    height: 34px;
    width: 34px;
    border: 1px solid #ebebeb;
    border-radius: 2px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    a {
      font-size: 14px;
      color: #5f75aa;
      text-decoration: none;
      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
      span {
        padding: 8px;
      }
    }
  }
}
.active {
  background-color: #de8f75;
  border-radius: 2px;
  border: 1px solid #de8f75 !important;
  a {
    color: #fff !important;
    font-weight: 700;
    font-size: 14px;
  }
}
.button-buy {
  margin-top: 50px;
}
.short-description {
  font-size: 14px;
  margin-top: 30px;
  line-height: 200%;
}
.colors {
  display: flex;
  margin-top: 0px;
  li + li {
    margin-left: 12px;
  }
  button {
    &:focus {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid;
      border-radius: 100%;
      border-color: #de8f75 !important;
    }
  }
}
.short-description {
  h3 {
    display: none;
  }
}
@media (max-width: 1030px) {
  .button-buy {
    margin-top: 0px;
  }
  .sizes {
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      margin-right: 5px;
      margin-top: 5px;
    }
  }
  .short-description {
    p {
      margin-top: 0;
    }
    h3 {
      display: unset;
      margin-bottom: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #e0967e;
    }
  }
  .price {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #e35442;
  }
  .card-checkout {
    display: flex;
    justify-content: space-between;
  }
  .product-prices {
    display: flex;
    flex-direction: column;
    .discount {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      margin-left: 0px;
    }
  }
}
