.container {
  border-top: 1px;
  border-bottom: 1px;
  border-left: 0px;
  border-right: 0px;
  border-style: solid;
  border-color: #cccccc;
  color: #686868;
  align-items: center;
  ul {
    display: flex;
    justify-content: center;
    li + li {
      margin-left: 1rem;
    }
  }
  div {
    padding: 0px !important;
  }
}

.category {
  margin: 0rem 5rem 0rem 5rem;
  text-transform: uppercase;
  font-size: 14px;
  li + li {
    margin-left: 2rem !important;
  }
}
.right-content {
  align-items: center;
  li {
    display: flex;
    justify-content: center;
    p {
      margin: 0;
      margin-left: 5px;
      font-size: 12px;
      align-self: flex-end;
    }
  }
}

.search-field {
  input {
    width: 70%;
    border-width: 1px;
    height: 2rem;
    background-image: url('https://original-io.arthursfreitas.com.br/images/search.svg');
    background-position: 10px center;
    background-repeat: no-repeat;
    transition: padding 0.2s ease-in-out;
    &:focus {
      padding-left: 2.5rem;
      outline: none;
    }
    &::placeholder {
      text-align: right;
      font-size: 12px;
    }
  }
  img {
    position: absolute;
    margin-left: -25px;
  }
}

.right-content {
  li + li {
    margin-right: 2rem;
  }
}
.link-access {
  font-size: 12px;
}
