.divisor {
  border: 1px solid #aeb6c1;
}
.last-footer {
  display: flex;
  background-color: #de8f75;
  place-content: center;
  p {
    width: auto;
    font-size: 12px;
    color: #fff;
    margin-left: 20px;
    margin-right: 20px;
  }
  img {
    filter: brightness(3);
    margin-right: 30px;
    width: 100px;
  }
}
@media (max-width: 1030px) {
  .last-footer {
    flex-wrap: wrap;
    padding-bottom: 15px;
  }
}
